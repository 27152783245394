<template>
  <div>
    <div v-if="loaded && !tokenExpired">
      <StepProgress :steps="[[null, 'icn_account_off.svg', 'icn_account_on.svg'], ['icn_school_off.svg', 'icn_school_on.svg', null]]" :current-step="0">
      </StepProgress>

      <!-- Title and subtitle -->
      <p class="auth-subtitle-gray">{{ $t('REGISTER_TEACHER.TEACHER') }}</p>
      <h1 class="auth-title">{{ $t('REGISTER_ACCOUNT') }}</h1>

      <!-- Register Form -->
      <FormWrapper :send-form-data="validateData" class="page-form-wrapper">
        <template #form-content>
          <!-- First name -->
          <InputField
            :field-title="`${$t('FIRST_NAME')}`"
            :field-name="'firstName'"
            :rules="'required'"
            :type="'text'"
            :placeholder="$t('FIRST_NAME')"
            :cy-selector="'name-input'"
            :input-icon="require('../../../../assets/icons/icn_account.svg')"
            :input-icon-error="require('../../../../assets/icons/icn_error.svg')"
            :api-error-text="apiErrorText"
            :value="prefilledFormData ? prefilledFormData.firstName : ''"
            class="auth-form-element"
          />

          <!-- Last Name -->
          <InputField
            :field-title="`${$t('LAST_NAME')}`"
            :field-name="'lastName'"
            :rules="'required'"
            :type="'text'"
            :placeholder="$t('LAST_NAME')"
            :cy-selector="'name-input'"
            :input-icon="require('../../../../assets/icons/icn_account.svg')"
            :input-icon-error="require('../../../../assets/icons/icn_error.svg')"
            :api-error-text="apiErrorText"
            :value="prefilledFormData ? prefilledFormData.lastName : ''"
            class="auth-form-element"
          />

          <!-- Type -->
          <!-- todo was removed by client request-->
          <!-- todo was removed by client request-->
          <!-- todo was removed by client request-->
          <!--        <Dropdown id="teacher"-->
          <!--                  :field-title="`${$t('REGISTER_TEACHER.TYPE_TITLE')} `"-->
          <!--                  :dropdown-options="teacherTypes"-->
          <!--                  :placeholder="$t('REGISTER_TEACHER.TYPE')"-->
          <!--                  :field-name="'teacherType'"-->
          <!--                  :input-icon="require('../../../../assets/icons/temp/avatar.svg')"-->
          <!--                  :input-icon-error="require('../../../../assets/icons/temp/avatar_error.svg')"-->
          <!--                  :callback="() => {}"-->
          <!--                  :api-error-text="''"-->
          <!--                  class="auth-form-element"/>-->

          <!-- E-mail -->
          <InputField :field-title="`${$t('EMAIL_ADDRESS')}`"
                      :field-name="'email'"
                      :rules="'required'"
                      :type="'email'"
                      :placeholder="$t('EMAIL_ADDRESS')"
                      :cy-selector="'email-input'"
                      :api-error-text="apiErrorTextEmail"
                      :input-icon="require('../../../../assets/icons/icn_email.svg')"
                      :input-icon-error="require('../../../../assets/icons/icn_error.svg')"
                      :value="emailInput"
                      :bind-value="true"
                      class="auth-form-element">
            <template #additionalApiErrorSlot>
              <router-link :to="{ name: 'Login', query: { email: emailInput } }">
                <span class="auth-form-element__login-with-email"
                >Inloggen met dit e-mailadres.</span>
              </router-link>
            </template>
          </InputField>

          <!-- Phone number -->
          <InputField
            :field-title="'Telefoonnummer'"
            :field-name="'phoneNumber'"
            :rules="'dutchPhoneNumber'"
            :type="'tel'"
            :placeholder="'Telefoonnummer'"
            :cy-selector="'email-input'"
            :api-error-text="apiErrorText"
            :input-icon="require('../../../../assets/icons/icn_phone.svg')"
            :input-icon-error="require('../../../../assets/icons/icn_error.svg')"
            :value="prefilledFormData ? prefilledFormData.phoneNumber : ''"
            class="auth-form-element"
          >
            <template #extra-content>
              <div class="phone-tooltip">
                <InfoTooltip :text="$t('REGISTER_TEACHER.PHONE_TOOLTIP')" />
              </div>
            </template>
          </InputField>

          <!-- Password field -->
          <PasswordConfirmation
            :field-title="`${$t('PASSWORD')}`"
            :field-title-confirm="`${$t('REPEAT_PASSWORD')}`"
            :placeholder="$t('PASSWORD')"
            :placeholder-confirm="$t('REPEAT_PASSWORD')"
            :input-icon="require('../../../../assets/icons/icn_password.svg')"
            :input-icon-error="require('../../../../assets/icons/icn_error.svg')"
            :show-error="false"
            :field-info="$t('PASSWORD_INFO')"
            :debounce="0"
            :custom-text-error-class="'text-error-right'"
            :api-error-text="authError"
            :is-matching="checkMatching"
            :display-api-error="false"
            :value-password="prefilledFormData ? prefilledFormData.password : ''"
            :value-confirmation="
              prefilledFormData ? prefilledFormData.confirmation : ''
            "
            class="auth-form-element"
          />

          <!-- Terms of use agreement -->
          <Checkbox :field-title="''"
                    :text="$t('REGISTER_TEACHER.AGREE')"
                    :field-name="'agreeToTerms'"
                    :value="prefilledFormData ? prefilledFormData.agreeToTerms : false"
                    :rules="'required'"
          >
            <template #additional-text>
              <a class="link-main" :href="promotionalWebsiteUserPolicyUrl" target="_blank">{{ $t('REGISTER_TEACHER.TERMS') }}</a>
            </template>
          </Checkbox>

          <!-- Receive emails/instructions -->
          <Checkbox
            :field-title="''"
            :text="$t('REGISTER_TEACHER.RECEIVE_EMAILS')"
            :field-name="'receiveEmails'"
            :rules="''"
            :value="prefilledFormData && prefilledFormData.receiveEmails !== undefined ? prefilledFormData.receiveEmails : true"
            class="auth-check-element"
          />

          <!-- Newsletter -->
          <Checkbox
            :field-title="''"
            :text="$t('REGISTER_TEACHER.NEWSLETTER')"
            :field-name="'receiveNewsletter'"
            :rules="''"
            :value="prefilledFormData ? prefilledFormData.receiveNewsletter : false"
            class="auth-check-element"
          />
        </template>


        <template #button-submit>
          <vue-recaptcha 
            :sitekey="recaptchaKey"
            @verify="onRecaptchaVerified"
            class="recaptcha-container"
          ></vue-recaptcha>

          <button type="submit" class="pink-button auth-button">
            {{ $t('CREATE_ACCOUNT') }}
          </button>
        </template>
      </FormWrapper>

      <!-- Warning Emails -->
      <BasicModal v-if="isBasicVersionDisplayed" />
    </div>
    <LinkExpired v-if="tokenExpired" 
      :title="$t('CONFIRM_TEACHER.EXPIRED_VERIFICATION_TOKEN_TITLE')"
      :description="$t('CONFIRM_TEACHER.EXPIRED_VERIFICATION_TOKEN_SUBTITLE')"
    />
  </div>
</template>

<script>
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import Checkbox from '@/components/yo-form/Checkbox'
import PasswordConfirmation from '@/components/yo-form/PasswordConfirmation'
// import Dr/**/opdown from '@/components/yo-form/Dropdown'
import { useRoute, useRouter } from 'vue-router'
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'
import InfoTooltip from '@/components/elements/InfoTooltip'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { SET_BASIC_MODAL_DATA } from '@/store/mutations'
import { useStore } from 'vuex'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { computed, ref } from '@vue/reactivity'
import StepProgress from '@/components/elements/StepProgress'
import ApiHandler from '@/utils/handlers/ApiHandler'
import { useI18n } from 'vue-i18n'
import { REGISTER_TEACHER } from '@/store/modules/auth/actions'
import { VueRecaptcha } from 'vue-recaptcha';
import LinkExpired from '@/components/partials/auth/LinkExpired'

export default {
  name: 'RegisterTeacher',
  components: {
    BasicModal,
    InfoTooltip,
    // Dropdown,
    Checkbox,
    FormWrapper,
    InputField,
    PasswordConfirmation,
    StepProgress,
    VueRecaptcha,
    LinkExpired
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const inviteToken = route.params.inviteToken
    const tokenExpired = ref(false);
    const loaded = ref(false);
    const store = useStore()
    const apiErrorTextEmail = ref()
    const emailInput = ref('')
    const { t } = useI18n()
    const promotionalWebsiteUserPolicyUrl = `${process.env.VUE_APP_PROMOTIONAL_WEBSITE_URL}/${t('ROUTE.PROMOTIONAL_WEBSITE_USER_POLICY')}`

    const modalController = ref(new ModalController())

    const recaptchaKey = process.env.VUE_APP_RECAPTCHA_KEY
    let recaptchaResponse = ref('')

    const registerFormData = localStorage.getItem('register_teacher_form')
    let prefilledFormData = {}
    if (registerFormData) {
      prefilledFormData = JSON.parse(registerFormData)
      emailInput.value = prefilledFormData.email
    }
    let inviteData = null

    // Verification flow
    if (inviteToken) {
      ApiHandler.get(`auth/verify-invite-token/${inviteToken}`).then(response => {
        if (response) {
          inviteData = response.data
          emailInput.value = response.data.email
          loaded.value = true
        }
      }).catch(error => {
        if(error.response.data.error_description === "TOKEN_EXPIRED") {
          tokenExpired.value = true
        }
        loaded.value = true
      });
    } else {
      loaded.value = true
    }

    const activeModal = computed(() => store.getters.getActiveBasicModal)

    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.WARNING_EMAILS
    )

    const isMatching = ref(false)


    function checkMatching(value) {
      isMatching.value = value
    }
    // todo remove
    // Get the teacher types.
    // getTeacherTypes()
    //
    // /** Teacher type **/
    // function getTeacherTypes() {
    //   return store.dispatch(GET_TEACHER_TYPES)
    // }
    //
    // const teacherTypes = computed(() => store.getters.getTeacherTypes)
    
    function onRecaptchaVerified(response) {
      recaptchaResponse.value = response
    }

    /** Form **/
    function validateData(data) {
      // If the user has not clicked the receiveEmails checkbox, a warning should be displayed.
      const checkEmailFormData = new FormData()
      if (!isMatching.value) {
        return false
      }
      checkEmailFormData.append('email', data.email)
      if (inviteToken) {
        checkEmailFormData.append('inviteToken', inviteToken)
      }
      emailInput.value = data.email
      ApiHandler.post('auth/email-available', checkEmailFormData).then(
        response => {
          if (!response.data.emailAvailable) {
            // Email is already in use, display error on field and exit validation method.
            apiErrorTextEmail.value = 'Dit e-mailadres is al in gebruik.'
            return
          }

          data.receiveNewsletter = data.receiveNewsletter ?? false

          if (data.receiveEmails) {
            moveToNextStep(data)
          } else {
            const payload = {
              modalOptions: {
                modalContent: BASIC_MODAL_CONTENT.WARNING_EMAILS,
                cancelCallback: null,
                executeCallback: moveToNextStep,
                data: data
              },
              activeModal: MODAL_NAMES.WARNING_EMAILS
            }
            store.commit(SET_BASIC_MODAL_DATA, payload)
          }
        }
      )
    }

    function moveToNextStep(data) {
      // todo remove
      // Get the ID of the selected teacher type.
      data.teacherTypeId = 1
      if (inviteToken) {
        data.inviteToken = inviteToken
      }
      if (inviteData) {
        data.inviteData = inviteData
      }

      data.recaptchaResponse = recaptchaResponse.value;

      // TODO: improve localStorage handling

      localStorage.setItem('register_teacher_form', JSON.stringify(data))
      modalController.value.resetActiveModal()
      store.dispatch(REGISTER_TEACHER, data).then((response) => {
        if (response.data) {
          if (response.data.school.id && !response.data.emailVerified) {
            router.push({ name: ROUTE_NAMES_AUTH.REGISTER_EMAIL_SENT, query: { email: response.data.email } })
          } 
          else if (!response.data.school.id) {
            localStorage.setItem('register_teacher_response', JSON.stringify(response.data))
            router.push({ name: ROUTE_NAMES_AUTH.SELECT_SCHOOL })
          }
          else {
            router.push({ name: ROUTE_NAMES_AUTH.LOGIN, params: { userType: 'teacherPortal' } })
          }
        }
      }).catch((error) => {
        if (error.response.data.error_description === 'TOKEN_EXPIRED') {
          tokenExpired.value = true
        }
      })
    }

    return {
      authError: undefined,
      apiErrorText: '',
      tokenExpired,
      loaded,
      promotionalWebsiteUserPolicyUrl,
      prefilledFormData,
      emailInput,
      isBasicVersionDisplayed,
      checkMatching,
      apiErrorTextEmail, // TODO: Get from the API.
      /** Teacher type **/
      // teacherTypes,

      recaptchaKey,
      onRecaptchaVerified,

      /** Form **/
      validateData
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base.variables';
@import '~@/assets/css/base.mixins';

.phone-tooltip {
  @include position(absolute, $top: rem(52), $right: rem(-32));
  transform: translateY(-50%);
  //cursor: pointer;
}

.auth-form-element__login-with-email {
  color: var(--blue_link);
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
}

:deep .check-mark {
  top: 50% !important;
  transform: translateY(-50%);
}

.recaptcha-container {
  display: flex;
  justify-content: center;
}
</style>
