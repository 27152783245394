<template>
  <div class="wrapper">
    <figure class="image-wrapper">
      <img src="@/assets/icons/icn_email.svg" class="page-icon" alt="email-icon"/>
    </figure>
    <h1 class="auth-title">{{ title }}</h1>
    <p class="auth-subtitle page-subtitle">{{ description }}</p>
    <button type="button" class="pink-button" @click="backToLogin()">{{ $t('BACK_TO_LOGIN') }}</button>
  </div>
</template>

<script setup>
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth';
import { useRoute, useRouter } from 'vue-router'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  }
});

const router = useRouter()

function backToLogin() {
  router.push({ name: ROUTE_NAMES_AUTH.LOGIN });
}

</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.wrapper {
  margin-top: rem(56);
}

.image-wrapper {
  height: rem(100);
  margin-bottom: rem(40);

  .page-icon {
    height: inherit;
    width: auto;
  }
}

.auth-title {
  margin-bottom: rem(20);
}

.auth-subtitle {
  margin-bottom: rem(40);
}

.link {
  cursor: pointer;
  color: var(--blue_link);
  font-weight: bold;
}
</style>